
import { Action, Getter } from "vuex-class";
import { Component, Ref, Mixins } from "vue-property-decorator";
import { FormBase, FormInput, FormSubmit, FormSelect, FormCheckbox } from "@/components/forms";
import { CanGenerateTimelineEntries } from "@/mixins/can-generate-timeline-entries";
import { HasFormErrors } from "@/mixins/has-form-errors";
import { bus } from "@/main";
import { DocumentFolder, DocumentType, PatchDocumentPayload, DocumentAccess } from "@/store/modules/document.store";

@Component({
    components: {
        LayoutModal: () => import("@/layouts/components/modal.vue"),
        FormBase,
        FormInput,
        FormSelect,
        FormCheckbox,
        FormSubmit,
    },
})
export default class DocumentUpdateModal extends Mixins(HasFormErrors, CanGenerateTimelineEntries) {
    @Getter("activity/viewing") activity!: Activity;
    @Action("document/patch") patchDocument!: (payload: PatchDocumentPayload) => Promise<Document>;

    @Ref() form!: FormClass;

    DocumentAccess = DocumentAccess;

    payload: PatchDocumentPayload = {
        type: null,
        id: NaN,
        meta: {
            folder: null,
            access: [],
        },
    };

    show: boolean = false;

    get foldersTypes() {
        return [
            {
                label: this.$t(`views.documents.folders.${DocumentFolder.mediationAgreement}`),
                value: DocumentFolder.mediationAgreement,
            },
            {
                label: this.$t(`views.documents.folders.${DocumentFolder.legalProvisions}`),
                value: DocumentFolder.legalProvisions,
            },
            {
                label: "-- " + this.$t(`views.documents.folders.${DocumentFolder.syndic}`),
                value: DocumentFolder.syndic,
            },
            {
                label: this.$t(`views.documents.folders.${DocumentFolder.inspections}`),
                value: DocumentFolder.inspections,
            },
            {
                label: this.$t(`views.documents.folders.${DocumentFolder.photos}`),
                value: DocumentFolder.photos,
            },
            {
                label: "-- " + this.$t(`views.documents.folders.${DocumentFolder.advisuersfiche}`),
                value: DocumentFolder.advisuersfiche,
            },
            {
                label: "-- " + this.$t(`views.documents.folders.${DocumentFolder.board}`),
                value: DocumentFolder.board,
            },
            {
                label: "-- " + this.$t(`views.documents.folders.${DocumentFolder.pictures}`),
                value: DocumentFolder.pictures,
            },
            {
                label: "-- " + this.$t(`views.documents.folders.${DocumentFolder.measurementPlan}`),
                value: DocumentFolder.measurementPlan,
            },
            {
                label: "-- " + this.$t(`views.documents.folders.${DocumentFolder.socialMedia}`),
                value: DocumentFolder.socialMedia,
            },
            {
                label: this.$t(`views.documents.folders.${DocumentFolder.salesAgreement}`),
                value: DocumentFolder.salesAgreement,
            },
            {
                label: "-- " + this.$t(`views.documents.folders.${DocumentFolder.openSalesAgreement}`),
                value: DocumentFolder.openSalesAgreement,
            },
            {
                label: "-- " + this.$t(`views.documents.folders.${DocumentFolder.approvedSalesAgreement}`),
                value: DocumentFolder.approvedSalesAgreement,
            },
            {
                label: "-- " + this.$t(`views.documents.folders.${DocumentFolder.salesAgreementSub}`),
                value: DocumentFolder.salesAgreementSub,
            },
            {
                label: this.$t(`views.documents.folders.${DocumentFolder.varia}`),
                value: DocumentFolder.varia,
            },
            {
                label: this.$t(`views.documents.folders.${DocumentFolder.invoice}`),
                value: DocumentFolder.invoice,
            },
        ];
    }

    get fileTypes() {
        let types: DocumentType[] = [];
        if (!this.payload.meta || !this.payload.meta.folder) {
            return [];
        }
        if (this.payload.meta.folder === DocumentFolder.mediationAgreement) {
            types = [DocumentType.bemiddelingsOvereenkomst];
        } else if (this.payload.meta.folder === DocumentFolder.invoice) {
            types = [DocumentType.eigendomstitel, DocumentType.ovam, DocumentType.stedenbouw, DocumentType.rooilijnplan, DocumentType.kadastraalPlan, DocumentType.kasatraleLegger, DocumentType.kadastraleLeggerAlgemeneDelen, DocumentType.rvv, DocumentType.klim, DocumentType.watertoets, DocumentType.actaMaps, DocumentType.vgiUittreksel];
        } else if (this.payload.meta.folder === DocumentFolder.syndic) {
            types = [DocumentType.basisakte, DocumentType.jaarverslag1, DocumentType.jaarverslag2, DocumentType.jaarverslag3, DocumentType.verslagAv1, DocumentType.verslagAv2, DocumentType.verslagAv3, DocumentType.artikel57711, DocumentType.balans1, DocumentType.balans2, DocumentType.balans3, DocumentType.epcAlgDelen, DocumentType.blokpolis];
        } else if (this.payload.meta.folder === DocumentFolder.inspections) {
            types = [DocumentType.epc, DocumentType.elektrischeKeuring, DocumentType.offerteKeuringConform, DocumentType.stookolietankKeuring];
        } else if (this.payload.meta.folder === DocumentFolder.approvedSalesAgreement) {
            types = [DocumentType.aankoopbelofte];
        } else {
            types = [];
        }

        return types.map((v) => {
            return {
                label: this.$t(`views.documents.placeholders.${v}`),
                value: v,
            };
        });
    }

    mounted() {
        bus.$off("show-update-document");
        bus.$on("show-update-document", (document: Document) => {
            this.show = true;
            this.payload.id = document.id;
            this.payload.type = document.type;
            this.payload.meta = JSON.parse(JSON.stringify(document.meta)) ?? {
                folder: null,
                access: [DocumentAccess.internal],
            };
        });
    }

    async submit(form: FormClass) {
        try {
            await this.patchDocument(this.payload);

            this.handleClose();

            form.reset();

            this.$toast.open({ message: this.$t("views.documents.create_success") as string, type: "success", position: "bottom-left" });

            this.setTimelineCheckUpdate(true);
        } catch (e) {
            this.errorResponse = this.formatErrors(e);
        }
    }

    updateAccess(access: DocumentAccess) {
        if (!this.payload.meta) {
            return;
        }

        if (this.payload.meta.access.includes(access)) {
            this.payload.meta.access.splice(this.payload.meta.access.indexOf(access), 1);
        } else {
            this.payload.meta.access.push(access);
        }
    }

    handleClose() {
        this.errorResponse = {
            status: 0,
            errors: [],
        };

        this.show = false;
    }
}
